<template>
   <v-container class="pa-0 pt-12 box room">
       <!-- <div class="">
           <span v-if="loading"> Loading... {{room_name}}</span>
           <span v-else-if="!loading && room_name"> Connected to {{room_name}}</span>
       </div> -->
       <v-row no-wrap no-gutters>
         <v-col cols="12" id="remoteTrack_Container">
           <div class="track placeholder remote d-flex align-center justify-center flex-column grey lighten-3 text-center" v-if="remoteTrack === false"
              :style="`background-image: url(${meeting[whoIsOther].photoURL_cropped})`">
             <v-icon class="d-block mb-2" size="44" color="white">mdi-camera-wireless-outline</v-icon>
             <h3 class="white--text text-shadow">Waiting for {{meeting[whoIsOther].firstName}}'s Camera</h3>
           </div>
             <!-- <div id="remoteTrack" ref="remoteTrackCam" v-show="remoteTrack"></div> -->
         </v-col>
         <v-col cols="12" id="localTrack_Container">
           <div class="track placeholder local d-flex align-center justify-center flex-column grey lighten-3 text-center" v-if="!localTrack && cameraOn"
           :style="`background-image: url(${meeting[whoIsUser].photoURL_cropped})`">
             <v-icon class="d-block mb-2" size="44" color="white">mdi-camera-wireless-outline</v-icon>
             <h3 class="white--text text-shadow">Waiting for Your Camera</h3>
           </div>
             <!-- <div id="localTrack" ref="localTrackCam" v-show="localTrack"></div> -->
         </v-col>
       </v-row>


       <!-- snackbar -->
       <snackbar :snackbar="snackbar" v-if="showSnackbar"></snackbar>


       <v-dialog
         v-model="statsDialog"
         max-width="320"
       >
         <v-card>
           <v-card-title class="headline">
             Stats
           </v-card-title>
           NAME: {{room_name}}<br/>
           ROOM:{{activeRoom}}<br/>
           CAMERAS:{{cameraList}}<br/>
           Constraints:{{constraints}}<br/>
           <!-- <template v-if="!$isEmpty(activeRoom.localParticipant)">
             DEVICES
             <span v-for="(track, index) in activeRoom.localParticipant.videoTracks" :key="`track-${index}`">
               {{track}}
             </span>
           </template> -->
           TRACKS:{{cameraTrack}}
           <h2>MESSAGES</h2>
           <div class="" v-for="(message, index) in messages" :key="`message-${index}`">
             {{message}}
           </div>
           <v-card-actions>
             <v-spacer></v-spacer>
             <v-btn text @click="statsDialog = false">Decline</v-btn>
           </v-card-actions>
         </v-card>
       </v-dialog>
   </v-container>
</template>

<script>
const snackbar = () => import("@/components/shared/snackbar.vue");
import Twilio, { isSupported, connect, createLocalTracks, createLocalVideoTrack } from 'twilio-video';
import {cloud} from '@/firebase';
import { isMobile, isIOS, isChrome, isSafari, isFirefox, osName } from 'mobile-device-detect';
export default {
 name: "Room",
 components:{snackbar},
 data() {
   return {
     loading: false,
     room_name: null,
     data: {},
     localTrack: false,
     localTracks:[],
     remoteTrack: false,
     cameraTrack: [],
     activeRoom: '',
     previewTracks: '',
     identity: '',
     rooms: null,
     messages:[],
     constraints: {
        audio: true,
        video: { width: 720, height: 720, frameRate: 24, facingMode: 'user' }
      },
     cameraList: [],
     deviceList: [],
     tracks: [],
     isCameraAccessGranted: false,
     isMicrophoneAccessGranted:false,
     token: 'empty',
     snackbar: {},
     showSnackbar:false,
     statsDialog: false,
   }
 },
 props: ['meeting','micOn', 'cameraOn', 'switchCamera', 'statsOn', 'endSession'], // props that will be passed to this component
 computed:{
   userId: function (){ return this.$store.state.userId},
   whoIsUser: function(){
     return this.meeting.client.userId === this.userId ? 'client' : 'advisor'
   },
   whoIsOther: function(){
     return this.meeting.client.userId === this.userId ? 'advisor' : 'client'
   },
 },
 watch:{
   micOn: function(val){
     this.toggleMic(val)
   },
   statsOn: function(val){
     this.statsDialog = val;
   },
   endSession: function(val){
     if(val){this.leaveRoomIfJoined()}
   },
   switchCamera: function(val){
     let vThis = this;
     if (!this.$isUndefined(this.localTracks)) {
       this.stopMediaTracks();
     }
     this.constraints.video.facingMode = val;
     this.setupCameras().then((devices)=>{
       process.env.NODE_ENV === "development" ? console.log( 'devices', devices ) : null;
        let videoOnly = devices.filter(track => track.kind === 'videoinput');
        vThis.cameraList = devices;
        // this.getAccessToken().then(token => this.createChat(token))
        vThis.createLocalTracks();
       })
       .catch(error => {
         console.error(error);
       });
     process.env.NODE_ENV === "development" ? console.log( 'constraints', this.constraints ) : null;

   }
 },
 mounted() {
   // let room_name = this.room_name
   this.room_name = this.$route.params.meetingId;
   // this.setupCameras();
   this.setupChat();
   // TODO: update this for a popup
   if(!Twilio.isSupported){alert('not suported')}


   // When a user is about to transition away from this page,
   // disconnect from the room, if joined.
   window.addEventListener('beforeunload', this.leaveRoomIfJoined);
   window.addEventListener('pagehide', this.leaveRoomIfJoined);
   this.constraints = {
     // audio: this.micOn ? null : localStorage.getItem('audioDeviceId'),
     audio: this.micOn,
     video: { width: 1280, height: 720, frameRate: 24, facingMode: 'user' }
     // video: this.videoConstraints
   };
 },
 beforeDestroy(){
   this.leaveRoomIfJoined();
 },
 methods: {
   async setupCameras(){
     let devices = await navigator.mediaDevices.getUserMedia(this.constraints)
     .then(stream => {
       this.$refs.remoteTrackCam.srcObject = stream;
       process.env.NODE_ENV === "development" ? console.log( 'STREAM?!', stream ) : null;
       return navigator.mediaDevices.enumerateDevices();
     })
     return devices
   },
   createLocalTracks(){
     let vThis = this;
     Twilio.createLocalTracks(vThis.constraints).then(tracks =>{
       vThis.localTracks = tracks;
       tracks.forEach(track => {
         // if(track.kind === 'video'){
           vThis.activeRoom.localParticipant.publishTrack(track)
           vThis.$nextTick(function(){
             vThis.trackSubscribed(track, 'localTrack')
           })
         // }
         // vThis.$refs.localTrackCam.appendChild(track.attach());
         // vThis.$nextTick(function() {
         //   vThis.localTrack = true;
         // });
       });
     })
   },
   setupChat(){
     // this.$emit('camera-list', this.cameraList);
     this.getAccessToken().then(token => this.createChat(token))
   },
   async getAccessToken(){
     let vThis = this;
     let getVideoRoom = cloud.httpsCallable('getVideoRoom');
     let token = await getVideoRoom({room: vThis.room_name}).then(function(result) {
       process.env.NODE_ENV === "development" ? console.log( 'results from getVideoRoom', result ) : null;
       vThis.token = result.data;
       return result.data;
     }).catch(function(error) {
       process.env.NODE_ENV === "development" ? console.log( 'error', error ) : null;
     });
     return token
   },
   createChat(token){
     let vThis = this;

   Twilio.createLocalTracks(this.constraints).then( (localTracks) => {
     vThis.localTracks = localTracks;
      return Twilio.connect(token, {
        type:'go',
        name: vThis.room_name,
        tracks: localTracks
      });
    }).then(room => {
      vThis.activeRoom = room;
      console.log(`Connected to Room: ${room.name}`);

        // roomJoined(room);
      vThis.localTrack = false;
      vThis.remoteTrack = false;
      // vThis.removeAllChildNodes(this.$refs.remoteTrackCam)
      // vThis.removeAllChildNodes(this.$refs.localTrackCam)

      // Show the LOCAL video when loaded
      room.localParticipant.videoTracks.forEach(publication => {
        vThis.$nextTick(function() {
          vThis.trackSubscribed(publication.track, 'localTrack')
          // vThis.$refs.localTrackCam.appendChild(publication.track.attach());
        });
      })

      //Attach the Tracks of the Room's Participants. ----> Already in room!!!
       room.participants.forEach(function(participant) {
         process.env.NODE_ENV === "development" ? console.log( 'Participants alread in room', participant ) : null;
         // When a Participant adds a Track, attach it to the DOM.
         vThis.trackSubscribed(participant.track, 'remoteTrack')

           participant.on('trackSubscribed', function(track) {
             vThis.trackSubscribed(track, 'remoteTrack')
           });
       });

      room.on('participantConnected', (participant)=>{
        console.log('Participant "%s" connected', participant.identity);
        // send message user connected
        vThis.triggerSnackbar({color: 'success', text:'Participant connected'});
        participant.on('trackSubscribed', track => vThis.trackSubscribed(track, 'remoteTrack'));
        participant.on('trackUnsubscribed', track => vThis.trackUnsubscribed(track, 'remoteTrack'));

        participant.tracks.forEach(publication => {
          if (publication.isSubscribed) {
            vThis.trackSubscribed(publication.track, 'remoteTrack');
          }
          publication.on('unsubscribed', track => {
            vThis.triggerSnackbar({color: 'error', text:'Participant Camera Disabled'})
            process.env.NODE_ENV === "development" ? console.log( 'track unsubscribed', track ) : null;
            vThis.trackUnsubscribed(publication.track, 'remoteTrack');
          })
          publication.on('trackDisabled', track => {
            process.env.NODE_ENV === "development" ? console.log( 'track unsubscribed', track ) : null;
            vThis.triggerSnackbar({color: 'error', text:'Participant Camera Disabled'})
          })
        });


      })

      room.once('participantDisconnected', (participant)=>{
        console.log('Participant "%s" disconnected', participant.identity);
        vThis.removeAllChildNodes('remoteTrack')
        // vThis.remoteTrack = false;
        // <div v-show="remoteTrack" id="remoteTrack"></div>
        // document.getElementById('remoteTrack').remove();
        // document.getElementById('remoteTrack').remove();
      })
      room.on('participantReconnected', remoteParticipant => {
        // assert.equals(remoteParticipant.state, 'connected');
        console.log(`${remoteParticipant.identity} has reconnected the signaling connection to the Room!`);
        /* Update the RemoteParticipant UI here */
      });


      if (isMobile) {
        document.addEventListener('visibilitychange', async () => {
          process.env.NODE_ENV === "development" ? console.log('document.visibilityState~~~~~~~',document.visibilityState  ) : null;
          // The app has been backgrounded. So, stop and unpublish your LocalVideoTrack.
          if (document.visibilityState === 'hidden') {
              // Snackbar messages
              vThis.triggerSnackbar({color: 'error', text:'Camera Temp Disabled'});
              //close tracks
              vThis.localTracks.forEach((track, index) =>{
                track.stop();
                room.localParticipant.unpublishTrack(track);
                vThis.removeAllChildNodes('localTrack');
                delete vThis.localTracks[index]
              })
              vThis.localTrack = false;
              vThis.$nextTick(function() {
                vThis.localTracks = [];
              });
            // The app has been foregrounded, So, create and publish a new LocalVideoTrack.
          } else {
            // snackbar messages
              vThis.triggerSnackbar({color: 'success', text:'Camera Reconnected'});
              process.env.NODE_ENV === "development" ? console.log( 'room.localParticipant.videoTracks',room.localParticipant.videoTracks ) : null;
              vThis.createLocalTracks();
            // room.localParticipant.videoTracks.forEach(publication => {
              // process.env.NODE_ENV === "development" ? console.log( 'publication.track', publication.track ) : null;
              // room.localParticipant.publishTrack(publication.track)
              // vThis.$refs.localTrackCam.appendChild(publication.track.attach());
              // vThis.$nextTick(function() {
              //   vThis.localTrack = true;
              // });
            // })
          }
        });
      }



    }, error => {
      console.error(`Unable to connect to Room: ${error.message}`);
    });
  },
  trackSubscribed(track, show){
    if(!this.$isUndefined(track)){
      //build parent div
      let parent = document.createElement('div')
      parent.id = show;
      parent.ref = `${show}Cam`;
      let container = document.getElementById(`${show}_Container`)
      process.env.NODE_ENV === "development" ? console.log( 'track SUB~~~~', container, parent, track ) : null;
      container.appendChild(parent);
      // show the video
      this.$nextTick(function() {
        this.appendTracks(track, show)
      });
    }
  },
  appendTracks(track, show){
    process.env.NODE_ENV === "development" ? console.log( 'tracks APPEND~~~~', show ) : null;
    document.getElementById(show).appendChild(track.attach());
    this[show] = true;
  },
  trackUnsubscribed(track, show){
    process.env.NODE_ENV === "development" ? console.log( 'tracks UNSUSCRIBED~~~~~~', track, show) : null;
    if(!this.$isUndefined(track)){
      this.removeAllChildNodes(show)
      track.detach();
    }
  },
  removeAllChildNodes(show, kind) {
    process.env.NODE_ENV === "development" ? console.log( 'removeAllChildNodes', show, document.getElementById(show) ) : null;
    this[show] = false;
    // if(!this.$isUndefined(this.$refs[`${show}Cam`]))
    if(show !== null){document.getElementById(show).remove();}
    // this.$refs[`${show}Cam`].remove();
    //hack for Safari
    // if(isMobile){
    //   this.$refs[kind].remove();
    // }
    // else{
    //   this.$refs[kind].querySelectorAll('*').forEach( n => {n.remove() });
    // }
  },
    // Leave Room.
  leaveRoomIfJoined() {
    if (this.activeRoom) {
      process.env.NODE_ENV === "development" ? console.log( 'LEAVING ROOM' ) : null;
      this.remoteTrack = false;
      this.localTrack = false;
      this.activeRoom.localParticipant.tracks.forEach(publication => {
        publication.track.stop();
        if(!this.$isUndefined(publication.track)){
          const attachedElements = publication.track.detach();
          console.log("unsubscribed from: " + publication.track)
          document.getElementById('localTrack').remove();
          document.getElementById('remoteTrack').remove();
          // attachedElements.forEach(element => element.remove());
        }
      });
      this.activeRoom.disconnect();
    }
  },
  stopMediaTracks() {
    this.localTracks.forEach(track => {
      track.stop();
    });
  },
  toggleMic(toggle){
    process.env.NODE_ENV === "development" ? console.log( 'toggle', toggle ) : null;
    this.activeRoom.localParticipant.audioTracks.forEach(publication => {
      if(toggle){publication.track.enable();}
      else{publication.track.disable();}
    });
  },
  triggerSnackbar(data){
    this.showSnackbar = true;
    this.snackbar = data;
  }
}
}
</script>

<style lang="scss">
.room{
;

   .remote_video_container {
     left: 0;
     margin: 0;
     border: 1px solid rgb(124, 129, 124);
   }
   .track{
     &.placeholder{
       min-height: 40vh;
       .v-icon, h3{
         z-index: 100;
       }
       &.remote{
         position: relative;
         background-size: cover;
       }
       &.local{
         position: relative;
         background-size: cover;
       }
       &:after {
         content: "";
         background-color: rgba(0,0,0,.5);
         background-repeat: repeat;
         position: absolute;
         display: block;
         top: 0px;
         right: 0px;
         bottom: 0px;
         left: 0px;
         opacity: 0.6;
         z-index: 0;
       }
     }
   }
   #localTrack video {
       margin: 0px;
       max-width: 100% !important;
       min-width: 100% !important;
       background-repeat: no-repeat;
   }
   #remoteTrack video {
       margin: 0px;
       max-width: 100% !important;
       min-width: 100% !important;
       background-repeat: no-repeat;
       margin-bottom: -7px;
   }
   .spacing {
     padding: 20px;
     width: 100%;
   }
   .roomTitle {
   }
 }
</style>
